@font-face {
  font-family: "SafePlace";
  src: local("SafePlace"),
    url("./fonts/SafePlace/ASafePlacetoFall.ttf") format("truetype");
}

html,
body {
  height: 100%;
}

#root {
  font-family: "SafePlace";
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: hidden;
  /* background: linear-gradient(#fff, #999); */
  /* background: linear-gradient(#e66465, #9198e5); */
  background-image: url("./assets/green-bg.png");
  background-size: cover;
  /* position: absolute; */
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: relative;
}

/* #root::before {
  content: "";
  background-image: url("./assets/watercolor_bg.png");
  background-color: #1071B9;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.75;
} */

.mainContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 55px;
}

/* #root > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.app {
  margin-top: -3em;
  overflow: hidden;
  min-height: 0;
  display: flex;
}

.app > div {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
  position: relative;
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
  width: 500px;
}

.currentlySelectedCard {
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}

.cardContainer {
  width: 90vw;
  max-width: 500px;
  height: 500px;
}

.hideCardContainer {
  display: none;
}

/* .hex-container {
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
} */

.hex {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 500px;
  height: 433px; /* width * 0.866 */
  /* background: red; */
  box-sizing: border-box;
  /* -webkit-clip-path: polygon(
    0% 50%,
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%,
    round 20px
  );
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%); */
}

.hex img {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* -webkit-clip-path: polygon(
    0% 50%,
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%
  );
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%); */
}

.hex-background {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 500px;
  height: 433px;
  /* -webkit-clip-path: polygon(
    0% 50%,
    25% 0%,
    75% 0%,
    100% 50%,
    75% 100%,
    25% 100%
  );
  -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%); */
}

.hexagon {
  overflow: hidden;
  visibility: hidden;
  /* -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
  cursor: pointer; */
}
.hexagon-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  /* -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg); */
}

.hexagon1 {
  width: 500px;
  height: 500px;
  margin: 0 0 0 -80px;
}

/* .slide-effect {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text,
.slideDown,
.slideUp {
  position: relative;
  font-family: tahoma;
  font-size: 35px;
  opacity: 0;
}

.text {
  top: 40px;
  font-weight: bold;
  animation: slideUp ease 0.4s forwards;
}

.slideUp {
  top: 40px;
  left: 10px;
  animation: slideUp ease 0.5s forwards 1.2s;
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-40px);
    opacity: 1;
  }
} 

@media only screen and (max-width: 600px) {
  .slide-effect,
  .text,
  .slideDown,
  .slideUp {
    font-size: 15px;
  }
}

*/

.slide-effect p {
  font-size: 25px;
  -webkit-animation: fadein 3s;
  -moz-animation: fadein 3s;
  -ms-animation: fadein 3s;
  -o-animation: fadein 3s;
  animation: fadein 3s;

  animation: signup-response 0.5s 1;
  -webkit-animation: signup-response 0.5s 1;
  animation-fill-mode: forwards;

  animation-delay: 10s;
  -webkit-animation-delay: 10s;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide-effect {
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.card {
  position: relative;
  /* background-color: #fff; */
  width: 90vw;
  max-width: 500px;
  /* width: 100%; */
  height: 90vw;
  max-height: 500px;
  /* box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30); */
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.cardContainer .swipe:first-child .card {
  /* -webkit-box-shadow: 2px 5px 11px 5px rgba(0,0,0,0.44); 
  box-shadow: 2px 5px 11px 5px rgba(0,0,0,0.44); */
}

.cardContent {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  height: 28px;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 2em auto;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 999em;
  border: none;
  color: #fff;
  font-family: "SafePlace";
  font-size: 18px;
  background-color: #8cc640;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  width: 140px;
  /* box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 5px 11px 5px rgba(0, 0, 0, 0.44);
  box-shadow: 2px 5px 11px 5px rgba(0, 0, 0, 0.44); */
}

.buttons button:hover {
  transform: scale(1.05);
}

.cta-bottom {
  display: none;
}

.cta-top {
  position: absolute;
  top: 0;
  right: 0;
}

.cta-container {
  justify-content: right;
  padding: 0 5px;
}

.cta-container a {
  margin: 0 10px;
  padding: 5px;
  justify-content: right;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  color: #1071b9;
  font-family: "SafePlace";
  font-size: 24px;
  display: inline-block;
  font-weight: bold;
  text-shadow: 1px 1px 1px #efeff0;
}

.cta-container a:hover {
  /* background-color: #903F97; */
  transition: 0.3s;
}

.logo {
  width: 70%;
  max-width: 350px;
  position: relative;
  margin-bottom: 2em;
}

.gif {
  width: 100%;
  position: relative;
  -webkit-box-shadow: 2px 5px 11px 5px rgba(0, 0, 0, 0.44);
  box-shadow: 2px 5px 11px 5px rgba(0, 0, 0, 0.44);
  margin-bottom: 10px;
}

.finished-text {
  position: relative;
  color: #1071b9;
  line-height: 1.2;
  text-shadow: 1px 1px 1px #efeff0;
}

.shuffle_button {
  background-color: #903f97 !important;
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

* {
  touch-action: manipulation;
}

@media screen and (max-width: 975px) {
  .cta-container a {
    font-size: 19px;
  }
}

@media screen and (max-width: 730px) {
  .cta-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 0 5px;
    text-align: left;
    width: 100%;
    margin-bottom: 2em;
  }

  .cta-bottom {
    display: block;
    /* position: absolute;
    bottom: 0;
    left: 0; */
    margin: 20px;
    display: flex;
    text-align: right;
  }

  .cta-container a {
    font-size: 16px;
  }

  .buttons button {
    width: 95px;
  }

  .slide-effect p {
    font-size: 18px;
  }

  .cta-top {
    display: none;
  }

  .logo {
    margin-top: 2em;
  }

  .hex {
    width: 300px;
    height: 260px;
  }

  .hex-background {
    width: 300px;
    height: 260px;
  }

  .card {
    max-width: 300px;
    max-height: 300px;
  }

  .cardContainer {
    max-width: 300px;
    max-height: 300px;
  }

  .swipe {
    width: 300px;
  }
}
